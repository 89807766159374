import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (page, options = null) => {
    let _parans = `?page=${page - 1}`;
    if (options) {
      if(options.search) {
        _parans += `&search=${options.search}`;
      }
      if (options.orderByKey) {
        _parans += `&order_by=${options.orderByKey}`
      }
      if (options.ascOrDes) {
        _parans += '&asc'
      }
    }
    return _http.get(`/api/user${_parans}`)
  },
  showModerators: (page, search = null) => {
    let _parans = `?page=${page - 1}&asc`;
    if (search) {
      _parans += `&search=${search}`;
    }
    return _http.get(`/api/user/show-moderators${_parans}`)
  },
  showModeratorsAll: (size) => {
    let _parans = `?size=${size}&asc`;
    return _http.get(`/api/user/show-moderators${_parans}`)
  },
  showRoles: () => {
    return _http.get(`/api/user/roles`)
  },
  find: (id) => {
    return _http.get(`/api/user/${id}`)
  },
  create: (payload) => {
    return _http.post('/api/user', payload)
  },
  update: (payload) => {
    return _http.put('/api/user', payload)
  },
  delete: (id) => {
    return _http.delete(`/api/user/${id}`)
  },
  userFuncTypes: () => {
    return _http.get(`/api/user/user-function-types`)
  },
  userTypeEditors: () => {
    return _http.get(`/api/user/by-user-type/4`)
  },
  autoComplete: (user_type_id) => {
    return _http.get(`/api/user/auto-complete/${user_type_id}`)
  }
}