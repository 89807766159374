<template>
  <viewcard--c
    :title="$route.params.id > 0 ? 'Atualizar Usuário' : 'Cadastrar Usuário'"
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btback="{}"
    :busy="loading"
    @clicked-save="validationForm"
    @clicked-delete="confirmDelete"
  >
    <hr class="p-0 m-0 mb-1" />
    <validation-observer ref="userRules">
      <b-form @submit.prevent>
        <b-row>
          <b-col md="4">
            <b-form-group label="Nome *">
              <validation-provider
                #default="{ errors }"
                name="Nome"
                rules="required"
                
              >
                <b-form-input
                  v-model="record.name"
                  placeholder="Nome do Usuário"
                  autocomplete="off"
                  disabled
                  
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="E-mail *">
              <validation-provider
                #default="{ errors }"
                name="E-mail"
                rules="required|email"
              >
                <div class="form-label-group">
                  <b-form-input
                    type="email"
                    v-model="record.email"
                    placeholder="Informe um e-mail"
                    autocomplete="off"
                    disabled
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Função *">
              <validation-provider
                #default="{ errors }"
                name="Função"
                rules="required"
              >
                <v-select
                  v-if="userTypes"
                  v-model="userTypeSelected"
                  :options="userTypes"
                  autocomplete="off"
                  :clearable="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Grupo Permissão">
              <v-select
                v-if="groups"
                v-model="groupsSelected"
                :options="groups"
                autocomplete="off"
                multiple
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Meta">
              <b-form-input
                v-model="record.goal"
                placeholder="meta..."
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Login *">
              <validation-provider
                #default="{ errors }"
                name="Login"
                rules="required"
              >
                <div class="form-label-group">
                  <b-form-input
                    v-model="record.user_name"
                    placeholder="Login"
                    disabled
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Interno (staff)">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                v-model="record.is_staff"
                :options="checkbox_values"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Burlar 40 minutos">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                switch
                v-model="record.is_allows_scheduling_any_time"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Ativo">
              <b-form-checkbox
                class="custom-control-success"
                name="check-button"
                v-model="record.active"
                switch
                :options="checkbox_values"
                disabled
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="id_external">
            <b-form-group label="ID do Accounts">
              <b-form-input
                  v-model="id_external"
                  disabled
                />
            </b-form-group>
          </b-col>          
        </b-row>
      </b-form>
    </validation-observer>
  </viewcard--c>
</template>
<script>
import _usersService from "@/services/users-service";
import _authService from "@/services/auth-service";
import _groupPermissionService from "@/services/group-permissions";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import pt_br from "vee-validate/dist/locale/pt_BR.json";
import { required, email } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      btedit: { permission: `permission.user.edit` },
      btcreate: { permission: `permission.user.create` },
      btdelete: { permission: `permission.user.delete` },
      loading: false,
      groups: [],
      groupsSelected: [],
      userTypes: [],
      userTypeSelected: null,
      record: {
        id: 0,
        name: "",
        email: "",
        user_name: "",
        goal: 0,
        is_staff: true,
        active: true,
        roles: [],
        is_allows_scheduling_any_time: false        
      },
      checkbox_values: [true, false],
      rolesSelected: null,
      roles: [],
      fields: [
        { key: "role", label: "Papel" },
        { key: "read", label: "Leitura" },
        { key: "create", label: "Criar" },
        { key: "update", label: "ATUALIZAR" },
        { key: "delete", label: "DELETAR" },
      ],
      id_external: null
    };
  },
  created() {
    this.getRoles();
    this.getGroups();
    this.getuserFuncTypes();
    localize("pt_BR", pt_br);
  },
  mounted() {
    this.getRecord();
  },
  methods: {
    dsRule(_code) {
      return this.roles.filter((f) => f.value === _code)[0].label;
    },
    validationForm() {
      this.$refs.userRules.validate().then((success) => {
        if (success) {
          this.save();
        }
      });
    },
    getuserFuncTypes() {
      _usersService
        .userFuncTypes()
        .then((res) => {
          this.userTypes = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getGroups() {
      _groupPermissionService
        .showAll()
        .then((res) => {
          this.groups = this.$utils.populardropdown(
            res.content,
            "name",
            "id",
            false,
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _usersService
          .find(this.$route.params.id)
          .then((res) => {
            this.record = res.content;
            this.id_external = res.content.id_external;

            this.groupsSelected = this.record.groupPermissions.map((m) => {
              return {
                label: m.permissionGroup.name,
                value: m.permissionGroup.id,
              };
            });
            this.userTypeSelected = {
              value: this.record.fixedUserType.id,
              label: this.record.fixedUserType.name,
            };
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      }
    },
    getRoles() {
      _usersService
        .showRoles()
        .then((res) => {
          if (this.$route.params.id <= 0) {
            this.record.roles = res.map((m) => {
              return {
                role: m.id,
                read: false,
                create: false,
                update: false,
                delete: false,
              };
            });
          }

          this.roles = this.$utils.populardropdown(
            res,
            "name",
            "id",
            false,
            false
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    save() {
      if (this.groupsSelected) {
        this.record.groupPermissions = this.groupsSelected.map((m) => {
          return {
            group_permission_id: m.value,
          };
        });
      }

      if (this.userTypeSelected) {
        this.record.type_user = this.$utils.getValueSelected(
          this.userTypeSelected
        );
      }

      const payload = { data: { ...this.record } };

      //promisse
      const _createOrUpdate =
        this.record.id <= 0
          ? _usersService.create(payload)
          : _usersService.update(payload);

      this.loading = true;
      _createOrUpdate
        .then(() => {
          this.$utils.toast("Notificação", "Usuário salvo com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    confirmDelete() {
      this.$swal({
        title: "Tem certeza?",
        text: "Isso não pode ser revertido!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        cancelButtonText: "Cancelar",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.excluir();
        }
      });
    },
    excluir() {
      this.loading = true;
      _usersService
        .delete(this.record.id)
        .then(() => {
          this.$utils.toast("Notificação", "Excluido com sucesso.");
          this.$router.go(-1);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>